import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { NotificationProfilesService } from '@core/services/notification-profiles.service';
import { LoadNotificationProfilesMultiAction } from '@core/store/notification-profiles/notification-profiles.actions';
import { map, switchMap } from 'rxjs/operators';
import { NotificationProfileState } from '@core/store/notification-profiles/notification-profiles.state';
import { first } from 'rxjs/internal/operators/first';

@Injectable({
  providedIn: 'root',
})
export class NotificationProfilesResolver {
  constructor(
    private store: Store,
    private notificationProfilesService: NotificationProfilesService
  ) {}

  resolve(): Observable<boolean> {
    return this.notificationProfilesService.getResultsApprovedProfiles().pipe(
      switchMap((profiles) =>
        this.store
          .dispatch(
            new LoadNotificationProfilesMultiAction(Object.values(profiles))
          )
          .pipe(
            switchMap(() =>
              this.store
                .select(NotificationProfileState.getNotificationProfiles)
                .pipe(map((getByIds) => getByIds(Object.values(profiles))))
            ),
            map((notificationProfiles) => {
              this.mapCountryNotificationProfiles(
                notificationProfiles,
                profiles
              );
              return true;
            })
          )
      ),
      first()
    );
  }

  private mapCountryNotificationProfiles(notificationProfiles, profiles) {
    const recipientsByCountry: {
      [country: string]: { cc: string[]; bcc: string[] };
    } = {};
    for (const [key, value] of Object.entries(profiles)) {
      const profile = notificationProfiles.find((p) => p?.id === value);
      recipientsByCountry[key.toLocaleLowerCase()] = {
        cc:
          profile?.ccRecipients?.map((recipient) => recipient?.recipient) ?? [],
        bcc:
          profile?.bccRecipients?.map((recipient) => recipient?.recipient) ??
          [],
      };
    }

    this.notificationProfilesService.recipientsByCountry = recipientsByCountry;
  }
}
