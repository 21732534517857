import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@root/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
  constructor(public authService: AuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request?.url?.startsWith(environment.apiUrl) ||
      request?.url?.startsWith(environment.reportManagerUrl)
    ) {
      return this.authService.getAccessToken$().pipe(
        take(1),
        switchMap((token: string) => {
          if (token) {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'Content-Type': 'application/json',
              },
            });
          }
          return next.handle(request);
        }),
        catchError((error) => throwError(error))
      );
    }
    return next.handle(request);
  }
}
