import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { ProductInventoryItemState } from '@app/modules/service-data/store/product-inventory-item.state';
import { SampleState } from '@app/modules/service-data/store/sample.state';
import { ServiceDataState } from '@app/modules/service-data/store/service-data.state';
import { SubscriptionKeyInterceptor } from '@core/services/subscription-key-interceptor.service';
import { DocumentsState } from '@core/store/documents/documents.state';
import { ProductConfigurationsState } from '@core/store/product-configuration/product-configuration.state';
import { NgxsModule } from '@ngxs/store';
import { environment } from '../../../environments/environment';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { AccessTokenInterceptor } from './services/access-token-interceptor.service';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthenticationService } from './services/authentication.service';
import { LocalStorageService } from './services/local-storage.service';
import { UnAuthGuardService } from './services/unauth-guard.service';
import { VersionService } from './services/version.service';
import { AccountManagerState } from './store/account-managers/account-managers.state';
import { BundleConfigurationsState } from './store/bundle-configuration/bundle-configuration.state';
import { CountriesState } from './store/countries/countries.state';
import { CouponsState } from './store/coupons/coupons.state';
import { KitsState } from './store/kits/kits.state';
import { LanguageState } from './store/languages/languages.state';
import { NotificationProfileState } from './store/notification-profiles/notification-profiles.state';
import { PantheonState } from './store/pantheon/pantheon.state';
import { PartnerTypeState } from './store/partner-types/partner-types.state';
import { PartnersState } from './store/partners/partners.state';
import { InvoicesState } from './store/payments/invoice-payments.state';
import { ProductsListState } from './store/products/products.state';
import { RunState } from './store/runs/runs.state';
import { DocumentApprovalSagaState } from './store/saga/document-approval-saga.state';
import { SigneeState } from './store/signees/signees.state';
import { UserAccountsState } from './store/user-accounts/user-accounts.state';
import { UserProfileState } from './store/user-profile/user-profile.state';
import { LayoutService, StateService } from './utils';

export const NB_CORE_PROVIDERS = [LayoutService, StateService, VersionService];

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forRoot(
      [
        UserProfileState,
        LanguageState,
        BundleConfigurationsState,
        CountriesState,
        PartnersState,
        ProductsListState,
        PartnerTypeState,
        ProductConfigurationsState,
        DocumentsState,
        RunState,
        AccountManagerState,
        PantheonState,
        KitsState,
        NotificationProfileState,
        UserAccountsState,
        SigneeState,
        InvoicesState,
        CouponsState,
        ServiceDataState,
        DocumentApprovalSagaState,
        SampleState,
        ProductInventoryItemState,
      ],
      {
        developmentMode: !environment.production,
      }
    ),
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
        LocalStorageService,
        AuthenticationService,
        AuthGuardService,
        UnAuthGuardService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AccessTokenInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: SubscriptionKeyInterceptor,
          multi: true,
        },
      ],
    };
  }
}
