import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private toastrService: NbToastrService,
    private translate: TranslateService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        error: (err: any) => {
          Sentry.captureException(
            `[API error] [Status: ${err.status}] ${err.url}`
          );
          if (err instanceof HttpErrorResponse) {
            if (err.status === 400 && err.error?.detail) {
              this.toastrService.danger(
                err.error.detail,
                this.translate.instant('toastr.error'),
                {
                  duration: 0,
                }
              );
            } else if (err.status === 400 && err.error?.Detail) {
              this.toastrService.danger(
                err.error.Detail,
                this.translate.instant('toastr.error'),
                {
                  duration: 0,
                }
              );
            } else if (
              err.status === 409 &&
              (err.error?.title || err.error?.Detail)
            ) {
              this.toastrService.danger(
                err.error?.title || err.error?.Detail,
                this.translate.instant('toastr.error'),
                {
                  duration: 0,
                }
              );
            } else if (err.status === 503 && err.error?.detail) {
              // Exporters 503 handle - max 2 concurrent exports limit.
              this.toastrService.danger(err.error.detail, err.error.title, {
                duration: 0,
              });
            }
          }
        },
      })
    );
  }
}
